<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title">
				<i class="far fa-comments-alt text-primary"></i>
				{{$t('inquiry.1')}}
				<button class="btn listgo_btn" @click="$router.push({path:'/'+$i18n.locale+'/inquiry/new'})" style="float: right;margin-top:0px;margin-bottom:10px">
					{{ $t('inquiry.7') }}
				</button>
				
				<!-- <input type="button" class="listgo_btn" :value="`${$t('inquiry.7')}`" @click="$router.push({path:'/'+$i18n.locale+'/inquiry/new'})" style="float: right;margin-top:0px;margin-bottom:10px"> -->
			</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="notice_table03">
				
				<table style="width:100%" class="main_table02">
					<thead>
						<tr>
							<th>{{$t('inquiry.2')}}</th>
							<th>{{$t('inquiry.13')}}</th>
							<th>{{$t('inquiry.4')}}</th>
						</tr>
					</thead>
					<tbody>
						
						<tr v-for="(item, index) in noticeList" :key="'notice'+index" @click="GotoNotice(item.code)">
							<td>{{item.title}}</td>
							<td>{{CheckReply(item.reply)}}</td>
							<td>{{item.date}}</td>
						</tr>
					</tbody>
					
				</table>
				
			</div>
			
		</div>
		
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>

<script>

const CryptoJS = require("crypto-js");

export default {
	data(){
		return{
			noticeList: [],
		}
	},
	mounted () {
		this.GetNoticeList();
	},
	methods : {
		CheckReply(reply){
			if(reply =='N'){
				return this.$t('inquiry.14')
			}else if(reply =='Y'){
				return this.$t('inquiry.15')
			}
		},
		GetNoticeList(){
			const lang = this.$i18n.locale;
			const body = {lang};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/board/GetInquiryList`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							
							this.noticeList = body.list;

						}
					}

				}
			).catch(() => {});
		},
		
		GotoNotice : function(code){
			
			this.$router.push({path:'/'+this.$i18n.locale+'/inquiry/detail/'+code}); 
		}
	}
}
</script>

<style>
</style>